import { privateAPI } from './index';

export const helpandsupportAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getAllReports: build.query({
      query: params => ({
        url: '/admin-dashboard/report-list/',
        method: 'GET',
        params
      }),
      providesTags: ['Reports'],
    }),
    getSingleReports: build.query({
      query: body => ({
        url: `/admin-dashboard/report/${body.report_id}/`,
        method: 'GET',
      }),
      providesTags: ['Reports'],
    }),
    assignReports: build.mutation({
      query: body => ({
        url: '/admin-dashboard/update-report/',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Reports'],
    }),
    changeReportsStatus: build.mutation({
      query: body => ({
        url: `/admin-dashboard/update-report-detail/${body.id}/`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Reports'],
    }),
  }),
});
export const {
  useGetAllReportsQuery,
  useGetSingleReportsQuery,
  useAssignReportsMutation,
  useChangeReportsStatusMutation,
} = helpandsupportAPI;
