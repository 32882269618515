/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// import useAuthInfo from 'customHooks/useAuthInfo';

function MetaTagsHelmet({ moduleName }) {
  // const { companyDetail: { company_name: companyName = 'Company' } = {} } = useAuthInfo();
  const companyName = 'Luxury Booking App';
  return (
    <Helmet>
      <title>{`${moduleName} - ${companyName}`}</title>
      <meta
        name="description"
        content={`CRM - ${companyName}`}
      />
    </Helmet>
  );
}

MetaTagsHelmet.propTypes = {
  moduleName: PropTypes.string.isRequired,
};

export default MetaTagsHelmet;
