import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import ChatBox from './components/ChatBox';

function Chat() {
  return (
    <Container variant="portal" className="py-4">
      <Typography variant="h3" className="mb-3" color="primary">
        Inbox
      </Typography>
      <Box className="bg-white common-border">
        <ChatBox />
      </Box>
    </Container>
  );
}

export default Chat;
