import React, { useEffect, useState } from 'react';
import { Button, Paper, Stack, Typography, Box, Chip, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import SectionLoader from 'containers/common/loaders/SectionLoader';
import { useChangeReportsStatusMutation, useGetSingleReportsQuery } from 'services/private/helpandsupport';
import moment from 'moment';
import { useCreateRoomMutation } from 'services/private/chat';
import { useSelector } from 'react-redux';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import ConfirmationPopup from 'containers/common/components/ConfirmationPopup';
import { toTitleCase } from 'utilities/helpers';
import CommonMenu from 'containers/common/components/CommonMenu';
import BackBtn from 'containers/common/components/BackBtn';
import GridLabelValuePair from './components/GridLabelValuePair';
import { ticketStatusOptions } from '../utilities/data';

function TicketDetail() {
  const [isOpenConModal, setIsOpenConModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { id } = useParams();
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [updateReport, { isError, isLoading }] = useChangeReportsStatusMutation();
  useHandleApiResponse(isError, isLoading, 'Ticket Status Update successfully',);
  const { data, isFetching } = useGetSingleReportsQuery({ report_id: id }, { skip: !id });
  const [createRoom, { data: roomDetail, isSuccess: roomSuccess }] =
    useCreateRoomMutation();
  const handelCreateRoom = async (reporter, reporterNumber) => {
    await createRoom({ owner: user?.profile?.id, partner: reporter, dispute: reporterNumber });
  };
  const toggleConModal = () => setIsOpenConModal(!isOpenConModal);
  useEffect(() => {
    if (roomSuccess) {
      navigate(`/chat?room=${roomDetail?.id}`, {
      });
    }
  }, [roomSuccess]);
  const handleItemClicked = async item => {
    setSelectedUser(item);
    toggleConModal();
  };
  const handleOnConfirmation = async () => {
    await updateReport({ id: data?.report_number, status: selectedUser.value });
    toggleConModal();
  };
  function getStatusColor(status) {
    if (status === 'closed') {
      return 'error';
    }
    if (status === 'pending') {
      return 'warning';
    }
    return 'success';
  }
  const isTicketOwner = data?.receiver === user?.profile?.id;
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={4}
      >
        <Typography variant="h3" className="noWrap">
          Ticket Detail
        </Typography>
      </Stack>
      <Paper className="p-3">
        {isFetching ? (
          <SectionLoader />
        ) : (
          <>
            <Grid container className="d-flex justify-content-between flex-wrap">
              {data.booking && (
                <Grid item xs={3} md={3}>
                  <Chip
                    sx={{ textTransform: 'capitalize' }}
                    label={`BOOKING iD: ${data?.booking?.hotel_booking?.hotel_reference_number}`}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={data?.booking ? 8 : 12}
                md={data?.booking ? 8 : 12}
                className="d-flex justify-content-end gap-2 mb-3"
              >
                <Chip
                  sx={{ textTransform: 'capitalize' }}
                  label={toTitleCase(data.status)}
                  color={getStatusColor(data?.status)}
                />
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={data?.status === 'closed' || data?.status === 'pending' || !isTicketOwner}
                    onClick={() => {
                      handelCreateRoom(data?.reporter, data?.report_number);
                    }}
                  >
                    Start chat
                  </Button>
                </Box>
                <Box>
                  <CommonMenu
                    menus={ticketStatusOptions}
                    func={handleItemClicked}
                    selectedOption={data?.receiver ? 'Status' : 'Assign Ticket first'}
                    disabled={data?.status === 'closed' || data?.receiver === null}
                    isSimple
                  />
                </Box>
                <Box>
                  <BackBtn />
                </Box>

              </Grid>
            </Grid>

            <Grid container className="d-flex justify-content-between flex-wrap mt-2">
              <Grid item xs={12} md={8}>
                <GridLabelValuePair label="Ticekt #" value={data?.report_number} />
                <GridLabelValuePair
                  label="Date"
                  value={moment(data?.created_at).format('YYYY-MM-DD')}
                />
                <GridLabelValuePair label="Username" value={data?.reporter_username} />
                <GridLabelValuePair
                  label="Receiver Name"
                  value={`${data?.reporter_first_name} ${data?.reporter_last_name}`}
                />
                <GridLabelValuePair label="Description" value={data?.description} />
                <GridLabelValuePair label="Subject" value={data?.subject} />
                <GridLabelValuePair label="Attachments" value={data?.report_images} />
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
      <ConfirmationPopup
        isOpen={isOpenConModal}
        toggle={toggleConModal}
        onConfirmation={handleOnConfirmation}
        message="Are you sure you want to change the Ticket Detail Status?"
      />
    </>
  );
}

export default TicketDetail;
