import { privateAPI } from './index';

export const authAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    logout: build.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
    }),
    userBaseInfo: build.query({
      query: () => '/auth/me/',
      providesTags: ['GetUserDetails']
    }),
    loadUser: build.query({
      query: () => '/auth/me/',

    }),
  }),
});

export const {
  useLogoutMutation,
  useUserBaseInfoQuery,
  useLoadUserQuery,
  useLazyLoadUserQuery

} = authAPI;
