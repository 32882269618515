// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-pagination_pagination__u9thQ {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/styles/common/card-pagination.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;EACA,mBAAA;AACJ","sourcesContent":[".pagination{\n    display: flex;\n    justify-content: end;\n    margin-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `card-pagination_pagination__u9thQ`
};
export default ___CSS_LOADER_EXPORT___;
