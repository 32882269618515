import React from 'react';
import propTypes from 'prop-types';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { saveAs } from 'file-saver';

// COMPONENTS & STYLES
import styles from 'styles/common/chat.module.scss';
import { checkIsMessageSentByMe, formatName, setIconByFileType } from 'utilities/helpers';
import { ChatMessageBoxStyles } from 'styles/mui/containers/chat-box-styles';

function MessageItem({ msgItem, isPending }) {
  return (
    <Box
      id={`message-${msgItem?.id}`}
      className={`d-flex ${checkIsMessageSentByMe(msgItem?.owner_user_type) || isPending
        ? 'flex-row-reverse ms-auto'
        : 'flex-row me-auto'} gap-2 my-2 mw-75`}
      sx={{ opacity: isPending ? 0.5 : 1 }}
    >
      <Tooltip
        title={msgItem?.first_name ? formatName(msgItem?.first_name, msgItem?.last_name, msgItem?.username) : ''}
      >
        <Avatar src={msgItem?.owner_image} alt={msgItem?.first_name} />
      </Tooltip>
      <Box
        className="py-2 px-3"
        sx={{
          background: checkIsMessageSentByMe(msgItem?.owner_user_type) ? '#f6f4f5' : '#ffe3c5',
          borderRadius: checkIsMessageSentByMe(msgItem?.owner_user_type) ? '8px 8px 0 8px' : '0 8px 8px 8px',
          ...ChatMessageBoxStyles,
        }}
      >
        {msgItem?.attachment ? (
          <Box
            key={msgItem?.attachment?.id}
            className={`${styles.uploadedFile} pointer`}
            onClick={() => saveAs(msgItem?.attachment?.file)}
          >
            <Box className="d-flex flex-column justify-content-between">
              <Box className="d-flex">
                <img src={setIconByFileType(msgItem?.attachment?.file_name)} alt="file" />

                <Box className="d-flex flex-column">
                  <Typography variant="dashboardBody" className="weight-500 ms-2" color="primary">
                    {msgItem?.attachment?.file_name?.split('/')[1]?.slice(0, 15)}...`
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h5" className="weight-500 mt-2 d-block" color="primary">
                {msgItem?.message}
              </Typography>
            </Box>
          </Box>
        ) : (

          <Typography sx={{ fontSize: '14px', wordBreak: 'break-all' }}>
            {msgItem?.message}
          </Typography>

        )}
      </Box>
    </Box>
  );
}

MessageItem.propTypes = {
  msgItem: propTypes.object,
  isPending: propTypes.bool,
};

MessageItem.defaultProps = {
  isPending: false,
  msgItem: {},
};

export default MessageItem;
