import React from 'react';
import { Box, Stack, Grid, Typography } from '@mui/material';
import { LocationCity, KingBed } from '@mui/icons-material';

import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import { useGetAnalyticsQuery } from 'services/private/dashboard';
import { flexItemBox } from 'styles/mui/containers/layoutStyles';
import AnalyticsCard from './components/AnalyticsCard';
import { analyticsCardsList } from './utilities/data';
import TopCitiesTable from './components/TopCitiesTable';
import TopSellingHotelsTable from './components/TopSellingHotelsTable';

function Dashboard() {
  const { data } = useGetAnalyticsQuery();
  return (
    <>
      <MetaTagsHelmet moduleName="Dashboard" />
      <Typography className="my-4" variant="pageTitle">
        Dashboard
      </Typography>
      <Grid container spacing={4} className="mb-4">
        <Grid item xs={12} md={6} lg={3}>
          <AnalyticsCard
            Icon={analyticsCardsList.icon3}
            label={analyticsCardsList.label3}
            data={data?.total_number_of_customer}
            color="primary.main"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <AnalyticsCard
            Icon={analyticsCardsList.icon2}
            label={analyticsCardsList.label2}
            data={data?.total_bookings}
            color="#bfbfbf"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <AnalyticsCard
            Icon={analyticsCardsList.icon4}
            label={analyticsCardsList.label4}
            data={data?.total_requested_amount_before_tax}
            color="primary.main"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <AnalyticsCard
            Icon={analyticsCardsList.icon1}
            label={analyticsCardsList.label1}
            data={data?.total_requested_amount_after_tax}
            color="#bfbfbf"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box sx={flexItemBox}>
            <Stack spacing={1} alignItems="center" direction="row" mb={2}>
              <LocationCity sx={{ color: '#6A7383' }} />
              <Typography variant="cardHead">Top Cities</Typography>
            </Stack>
            <TopCitiesTable />
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box sx={flexItemBox}>
            <Stack spacing={1} alignItems="center" direction="row" mb={2}>
              <KingBed sx={{ color: '#6A7383' }} className="me-2" />
              <Typography variant="cardHead">Top Selling Hotels</Typography>
            </Stack>
            <TopSellingHotelsTable />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
