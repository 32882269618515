export const UsersTableHeadings = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'first_name',
    disablePadding: true,
    label: 'First Name',
  },
  {
    id: 'last_name',
    disablePadding: true,
    label: 'Last Name',
  },
  {
    id: 'gender',
    disablePadding: true,
    label: 'Gender',
  },
  {
    id: 'email',
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone_number',
    disablePadding: false,
    label: 'Phone Number',
  },
  {
    id: 'expire_membership',
    disablePadding: false,
    label: 'Membership Status',
  },
  {
    id: 'status',
    disablePadding: false,
    label: 'Status',
  },
];
export const test = {};
