import React, { useEffect, } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from 'styles/mui/theme';
import store from 'store';
import { Provider, useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { userLoaded } from 'store/slices/authSlice';
import { useUserBaseInfoQuery } from 'services/private/auth';
import useScrollToTop from 'customHooks/useScrollToTop';
import SuccessSnackbar from 'containers/common/components/SuccessSnackbar';
// import { ErrorBoundary } from 'react-error-boundary';
// import Fallback from 'containers/common/components/SomethingWentWrongUi';
import Router from './routes';

function App() {
  const dispatch = useDispatch();
  const { data: loadUserData } = useUserBaseInfoQuery();

  // eslint-disable-next-line no-unused-vars
  useScrollToTop();

  useEffect(() => {
    if (loadUserData) {
      dispatch(userLoaded(loadUserData));
    }
  }, [loadUserData]);

  return (

    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          Components={{ success: SuccessSnackbar }}
        >
          {/* <ErrorBoundary FallbackComponent={Fallback}> */}
          <Router />
          {/* </ErrorBoundary> */}
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>

  );
}

export default App;
