import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, Typography, TableCell, TableBody } from '@mui/material';

function NoFoundTable({ message, col }) {
  return (
    <TableBody>
      <TableRow colSpan={col}>
        <TableCell className="no-border" colSpan={col}>
          <Typography variant="body1" className="text-center d-block">{message}</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

NoFoundTable.propTypes = {
  message: PropTypes.string.isRequired,
  col: PropTypes.number,
};

NoFoundTable.defaultProps = {
  col: 8,
};

export default NoFoundTable;
