import React from 'react';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import { FormikField, FormikSelect } from 'shared/form';
import SubmitBtn from 'shared/form/SubmitBtn';
import { toLowerCase } from 'utilities/helpers';
import {
  useCreateAdminUsersMutation,
  useGetAllDepartmentQuery,
} from 'services/private/adminusers';
import { useNavigate } from 'react-router-dom';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import {
  superUserInitialValues,
  superUserValidationSchema,
} from '../utilities/formUtils';
import { getTransformedDepartmentOptions } from '../utilities/data';

function AddUserForm() {
  const { data } = useGetAllDepartmentQuery();
  const [CreateAdminUsers, { error, isSuccess }] = useCreateAdminUsersMutation();
  useHandleApiResponse(error, isSuccess, 'User Created Successfully!');
  const departmentOptions = getTransformedDepartmentOptions(data);
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={superUserInitialValues}
      validationSchema={superUserValidationSchema}
      onSubmit={async values => {
        const profile = {
          first_name: values.first_name,
          last_name: values.last_name,
          department: values.department,
        };
        const payload = {
          profile,
          username: values.email,
          user_type: values.role,
          email: toLowerCase(values.email),
        };
        const uploadRes = await CreateAdminUsers(payload);
        if (uploadRes?.data) {
          navigate('/users/admin-users');
        }
      }}
    >
      {() => (
        <Form className="p-5">
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} className="d-flex flex-column gap-4">
              <FormikField
                type="text"
                label="First Name"
                name="first_name"
                placeholder="First Name"
                isRequired
                isRow
              />
              <FormikField
                type="text"
                label="Email"
                name="email"
                placeholder="Email"
                isRequired
                isRow
              />
              <FormikSelect
                label="Department"
                name="department"
                isRequired
                options={departmentOptions}
                isRow
              />
            </Grid>
            <Grid item xs={12} md={6} className="d-flex flex-column gap-4">
              <FormikField
                type="text"
                label="Last Name"
                name="last_name"
                placeholder="Last Name"
                isRequired
                isRow
              />
              <FormikSelect
                label="Role"
                name="role"
                options={[
                  { value: 'admin', label: 'Admin' },
                  { value: 'staff', label: 'Staff' },
                ]}
                isRequired
                isRow
              />
            </Grid>
          </Grid>
          <SubmitBtn label="Add User" variant="contained" color="primary" />
        </Form>
      )}
    </Formik>
  );
}

export default AddUserForm;
