export const bookingTableHeadings = [
  {
    id: 'hotel_name',
    disablePadding: true,
    label: 'Hotel Name',
  },
  {
    id: 'created_at',
    disablePadding: true,
    label: 'Booking Date',
  },
  {
    id: 'booking_id',
    disablePadding: true,
    label: 'Booking ID',
  },
  {
    id: 'start_date',
    disablePadding: true,
    label: 'Check In',
  },
  {
    id: 'end_date',
    disablePadding: true,
    label: 'Check Out',
  },
  {
    id: 'requested_amount_after_tax',
    disablePadding: true,
    label: 'Total Price',
  },
  {
    id: 'rooms',
    disablePadding: false,
    label: 'Rooms',
  },
  {
    id: 'booking_status',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'Action',
  },
];
export const Options = [
  { value: 'view', label: 'View', selectedValue: 'View' },
];
