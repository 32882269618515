import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledLinkCard = styled(Link)(({ theme: { palette } }) => ({
  border: 'solid 1px #8080806b',
  borderRadius: '12px',
  display: 'flex',
  flexShrink: '0',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '10rem',
  padding: '4px',
  backgroundColor: '#ffffff',
  textDecoration: 'none',
  color: 'black',
  transition: 'color 0.2s ease-in-out',
  ':hover': {
    color: palette.primary.main,
  },
}));

export default StyledLinkCard;
