import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import AddUserForm from './components/AddUserForm';

function AddUser() {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding={4}>
        <Typography variant="h3">Add User</Typography>
      </Stack>
      <Paper>
        <AddUserForm />
      </Paper>
    </>
  );
}

export default AddUser;
