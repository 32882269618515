export const helpAndSupportTableHeadings = [
  {
    id: 'order_number',
    disablePadding: true,
    label: 'Ticket #',
  },
  {
    id: 'subject',
    disablePadding: true,
    label: 'Subject',
  },
  {
    id: 'description',
    disablePadding: true,
    label: 'Description',
  },
  {
    id: 'created_at',
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'request_type',
    disablePadding: true,
    label: 'Type',
  },

  {
    id: 'sender',
    disablePadding: true,
    label: 'Created By',
  },
  {
    id: 'sender',
    disablePadding: true,
    label: 'Assigned By',
  },
  {
    id: 'sender',
    disablePadding: true,
    label: 'Assigned To',
  },

  {
    id: 'status',
    disablePadding: false,
    label: 'Status',
  },
];
export const ticketStatusOptions = [
  { value: 'in_progress', label: 'InProgress', selectedValue: 'InProgress' },
  { value: 'closed', label: 'Close', selectedValue: 'Close' },
];

export const ticketTypeOptions = [
  {
    id: 'Name',
    disablePadding: true,
    label: 'Name ',
  },
  {
    id: 'Department',
    disablePadding: true,
    label: 'Department',
  },
  {
    id: 'Email',
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'Action',
    disablePadding: false,
    label: 'Action',
  },
];
