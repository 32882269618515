/* eslint-disable max-len */
import EgyliereLogo from 'assets/luxury_logo.png';
import EgyliereBg from 'assets/company-bg.png';
import DefaultImg from 'assets/img/avatar.png';
import PlaceholderImg from 'assets/img/image-placeholder.png';

// export const API_URL = 'https://client-egyliere.beyonderissolutions.com/api/';
// export const API_URL = 'https://stagingcrm.beyonderissolutions.com/api/';
// export const API_URL = 'https://egyliere-travel.beyonderissolutions.com/api/';
export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = 'https://production-egyliere.beyonderissolutions.com/api/';

export const STORAGE_URL = 'https://lux-crm-storage-prod.s3.amazonaws.com/media/';

export const COMPANY_NAME = 'Sublime';
export const ROWS_PER_PAGE = '20';
export const CARD_PER_PAGE = 9;
export const AMOUNT_REGEX = /^\d+(\.\d{0,2})?$/;
export const ROWS_PER_PAGE_OPTIONS = [ROWS_PER_PAGE, 50, 100];
export const DEFAULT_PARAMS = {
  limit: ROWS_PER_PAGE,
  offset: 0,
};
export const DECIMAL_REGEX = /^[0-9.]*$/;

export const EMAIL_REGEX =
  /^(([^`~!$%^&*?/|}{#=<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const maxDecimalPoints = value => (value ? AMOUNT_REGEX.test(value.toString()) : true);

// ACCOUNTING ROLES
export const ACCOUNTING_CONTROLLER = 'accounting_controller';
export const GLOBAL_ADMINISTRAROR = 'global_administrator';
export const ACCOUNTING_MANAGER = 'accounting_manager';
export const ACCOUNTING_STAFF = 'accounting_staff';
export const ACCOUNTING_STAFF_RECEIVABLE = 'accounting_staff_receivable';
export const ACCOUNTING_STAFF_PAYABLE = 'accounting_staff_payable';

// OPERATIONS ROLES
export const OPERATIONS_CONTROLLER = 'operations_controller';
export const OPERATIONS_MANAGER = 'operations_manager';
export const OPERATIONS_STAFF = 'operations_staff';
export const OPERATION_CONTROLLER = 'operations_controller';
export const OPERATION_MANAGER = 'operations_manager';
export const AUDIT_MANAGER = 'auditor';

// SUPER USER
export const SUPER_USER = 'superuser';

// Departments
export const HELP_AND_SUPPORT = 'help_and_support';
export const ADMIN = 'admin';
export const OPERATIONS = 'operations';

// ROLES
export const IS_ADMIN = 'admin';
export const IS_STAFF = 'staff';

// luxury explorer main company
export const LUXURY_COMPANY = 'Luxury Events and VIP Travel DMCC';
export const DEFAULT_PAGINATION_DATA = {
  count: 0,
  results: [],
  previous: null,
  next: null,
};

export const IMAGE_PLACEHOLDER = PlaceholderImg;
// luxury explorer main company

export const TOGGLE_PAYMENT_TERM_MODAL = 'togglePaymentTermModal';
export const TOGGLE_SUPPLIER_MODAL = 'toggleSupplierModal';
export const TOGGLE_WARNING_MODAL = 'toggleWarningModal';
export const TOGGLE_INFO_POPUP = 'toggleInfoPopup';
export const UPDATE_LAST_REF_NUM = 'updateLastReferenceNumber';
export const UPDATE_TAX_RATE = 'updateDefaultTaxRate';
export const TOGGLE_ACCOUNT_MODAL = 'toggleAccountModal';
export const TOGGLE_COMMON_MODAL = 'toggleCommonModal';
export const TOGGLE_CUSTOMER_MODAL = 'toggleAccountModal';
export const TOGGLE_SALES_PERSON_MODAL = 'toggleCommonModal';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_PRINT = 'YYYY-MM-DD';
export const DATE_FILTER_REPORT = 'YYYY-MM-DD';
export const DATE_FORMATE_ADD = 'yyyy-MM-DD';
export const supplierOpeningBalanceName = 'Supplier Opening Balance';
export const DEFAULT_IMG = DefaultImg;

export const COMPANY_DETAILS = {
  id: 1,
  company_name: 'Egyliere',
  location: '102 Nile Street, Dokki, Giza, 12311, Egypt',
  phone: '+20 237 499 092',
  trn_number: '347698530',
  company_email: 'accounting@egyliere.com',
  website: 'https://www.egyliere.com/contact-us',
  company_logo: EgyliereLogo,
  company_background: EgyliereBg,
  currency_symbol: 'DH',
};

export const SINGLE = 'Single';
export const MARRIED = 'Married';
export const YES = 'Yes';
export const NO = 'No';

export const maritalStatusOptions = [
  { value: SINGLE, label: SINGLE },
  { value: MARRIED, label: MARRIED },
];

export const choiceOptions = [
  { value: YES, label: YES },
  { value: NO, label: NO },
];

export const AVATAR = 'https://stagingcrm.beyonderissolutions.com/img/avatar.png';
