import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import NoFoundTable from 'containers/common/components/NoFoundTable';

function TopCitiesTableBody({ city }) {
  if (city?.length === 0) {
    return <NoFoundTable message="No Booking Found" col={12} />;
  }
  return (
    <TableBody className="p-3">
      {city?.map(data => (
        <TableRow
          className="material-table__row"
          key={data.id}
          role="button"
          tabIndex="-1"
        >
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.city_name || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center', p: 1 }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.total_bookings || 'N/A'}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
TopCitiesTableBody.propTypes = {
  city: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TopCitiesTableBody;
