import React from 'react';
import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import BookingTable from './components/BookingTable';

function Booking() {
  return (
    <><MetaTagsHelmet moduleName="Bookings" /><BookingTable /></>

  );
}

export default Booking;
