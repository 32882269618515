import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSearchParamsList } from 'utilities/helpers';

function useGetSearchParams() {
  const [searchParams] = useSearchParams();
  const searchValuesObj = useMemo(() => getSearchParamsList(searchParams) ?? {}, [searchParams]);
  return { ...searchValuesObj, searchParams };
}

export default useGetSearchParams;
