import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { sidebarItemStyles } from 'styles/mui/containers/layoutStyles';
import { largeTextSlice } from 'utilities/helpers';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import SidebarItem from './SidebarItem';

function SidebarNested({ title, icon, data, isOpen }) {
  const [open, setOpen] = useState(null);

  const primaryColor = useGetThemeColor();

  const handleMenuClick = event => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        onClick={handleMenuClick}
        className={`${isOpen ? '' : 'px-3'} menu-item`}
        sx={{
          ...sidebarItemStyles,
          '&.Mui-selected, &:hover': { backgroundColor: primaryColor },
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        {!isOpen && (
          <>
            <ListItemText primary={largeTextSlice(title, 8)} />
            <KeyboardArrowRight />
          </>
        )}
      </ListItemButton>
      <Menu
        key={open}
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        sx={{ left: isOpen ? '3rem' : '11.5rem', top: 20 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {data.map(link => (
          <Box key={link?.path} onClick={handleClose}>
            {link?.data ? (
              <SidebarNested
                title={link?.title}
                data={link?.data}
                icon={link?.icon}
                isOpen={isOpen}
                ismenu
              />
            ) : (
              <SidebarItem
                title={link?.title}
                icon={link?.icon}
                path={link?.path}
                isOpen={false}
                ismenu
              />
            )}
          </Box>
        ))}
      </Menu>
    </>
  );
}

SidebarNested.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  data: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
};

SidebarNested.defaultProps = {
  title: 'Link',
  icon: null,
  data: [],
};

export default SidebarNested;
