import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Toolbar, Typography } from '@mui/material';
import { useGetAllReportsQuery } from 'services/private/helpandsupport';
import withTable from 'hoc/withTable';
import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import useGetParams from 'customHooks/useGetParams';
import { TableContext } from 'context/TableContext';
import useAuthInfo from 'customHooks/useAuthInfo';
import HelpAndSupportTable from './components/HelpAndSupportTable';
import AssignTicketsModal from './components/AssignTicketsModal';

function HelpAndSupport({ tableProps }) {
  const { selected, deleteToggle } = tableProps;
  const [apiParams] = useGetParams();
  const { isSuperUser, isHelpAndSupportHead } = useAuthInfo();
  const { data: itemsData = {}, isLoading } = useGetAllReportsQuery(apiParams, {
    skip: !apiParams,
  });
  const { results: items = [], count = 0 } = itemsData;
  const contextValues = useMemo(
    () => ({ ...tableProps, data: items, dataCount: count, isLoading }),
    [tableProps, items, isLoading, count]
  );

  return (
    <>
      <MetaTagsHelmet moduleName="Help & Support" />
      {selected.length === 0 && (
        <Typography className="my-4" variant="pageTitle">
          Customer Service
        </Typography>
      )}

      {selected.length > 0 && (isSuperUser || isHelpAndSupportHead) && (
        <Toolbar className="justify-content-between p-4">
          <Button
            color="primary"
            variant="contained"
            size="small"
            className="icon-btn"
            onClick={deleteToggle}
          >
            Ticket Assign
          </Button>

          <Box className="pt-2">
            <Box className="green-bubble" /> {selected.length} item
            {selected.length > 1 && 's'} selected{' '}
          </Box>
        </Toolbar>
      )}
      <TableContext.Provider value={contextValues}>
        <AssignTicketsModal />
        <HelpAndSupportTable />
      </TableContext.Provider>
    </>
  );
}
HelpAndSupport.propTypes = {
  tableProps: PropTypes.object.isRequired,
};

export default withTable(HelpAndSupport);
