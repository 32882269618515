import React from 'react';
import { Typography } from '@mui/material';
import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import UsersTable from './components/UsersTable';

function Users() {
  return (
    <>
      <MetaTagsHelmet moduleName="Users" />
      <Typography className="my-4" variant="pageTitle">
        System Users
      </Typography>
      <UsersTable />
    </>
  );
}

export default Users;
