import React from 'react';
import { Paper, Table, TableContainer, } from '@mui/material';
import TableLoader from 'containers/common/components/TableLoader';
import withTable from 'hoc/withTable';
import { useGetAnalyticsQuery } from 'services/private/dashboard';
import TopCitiesTableHead from './TopCitiesTableHead';
import TopCitiesTableBody from './TopCitiesTableBody';

function TopCitiesTable() {
  const { data, isFetching } = useGetAnalyticsQuery();
  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader>
          <TopCitiesTableHead
            rowCount={0}
          />
          {isFetching ? (
            <TableLoader />
          ) : (
            <TopCitiesTableBody
              city={data?.top_cities}
            />
          )}

        </Table>

      </TableContainer>
    </Paper>

  );
}
export default withTable(TopCitiesTable);
