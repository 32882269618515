// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-field_formGroup__jN0j\\+ {
  position: relative;
}
.search-field_formGroup__jN0j\\+ .search-field_searchIcon__iTE\\+a {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 50%;
  left: 15px;
  translate: 0 -50%;
}
.search-field_formGroup__jN0j\\+ .search-field_searchField__bxIXQ {
  background: #f6f4f5;
  border-radius: 20px;
  padding: 10px 10px 10px 45px;
  outline: none;
  width: 100%;
  border: transparent;
  color: #D4B35B;
  font-family: "Poppins", sans-serif;
}
.search-field_formGroup__jN0j\\+ .search-field_searchField__bxIXQ::placeholder {
  color: #D4B35B;
}
.search-field_formGroup__jN0j\\+ .search-field_loader__HB4X0 {
  position: absolute;
  right: 35px;
  top: 50%;
  translate: 0 -50%;
}`, "",{"version":3,"sources":["webpack://./src/styles/common/search-field.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,iBAAA;AACJ;AAEE;EACE,mBAAA;EACA,mBAAA;EACA,4BAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,cAAA;EACA,kCAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAIE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,iBAAA;AAFJ","sourcesContent":[".formGroup {\n  position: relative;\n\n  & .searchIcon {\n    width: 21px;\n    height: 21px;\n    position: absolute;\n    top: 50%;\n    left: 15px;\n    translate: 0 -50%;\n  }\n\n  & .searchField {\n    background: #f6f4f5;\n    border-radius: 20px;\n    padding: 10px 10px 10px 45px;\n    outline: none;\n    width: 100%;\n    border: transparent;\n    color: #D4B35B;\n    font-family: 'Poppins', sans-serif;\n\n    &::placeholder {\n      color: #D4B35B;\n    }\n  }\n\n  & .loader {\n    position: absolute;\n    right: 35px;\n    top: 50%;\n    translate: 0 -50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formGroup": `search-field_formGroup__jN0j+`,
	"searchIcon": `search-field_searchIcon__iTE+a`,
	"searchField": `search-field_searchField__bxIXQ`,
	"loader": `search-field_loader__HB4X0`
};
export default ___CSS_LOADER_EXPORT___;
