/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar } from '@mui/material';
import Notifications from 'containers/pages/chat/notifications/Notifications';
import {
  topbarStyling,
  topbarRightStyling,
} from 'styles/mui/containers/topbar';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import TopbarProfile from './TopbarProfile';

function Topbar({ isOpen }) {
  const primaryColor = useGetThemeColor();
  return (

    <Toolbar
      sx={{ ...topbarStyling, backgroundColor: 'white', width: isOpen ? 'calc(100% - 120px)' : 'calc(100% - 270px)', }}
      className="no-print d-flex justify-content-between align-items-center overflow-hidden"
    >
      <Box className="d-flex flex-col gap-2">
        <Box sx={{ maxWidth: '67%', fontSize: '18px', fontWeight: '500', }}>
          Welcome to
        </Box>
        <Box sx={{ maxWidth: '67%', fontSize: '18px', fontWeight: '500', color: primaryColor }}>
          Luxury Booking App
        </Box>
      </Box>

      <Box className="d-flex align-items-center gap-4">
        <Notifications />
        <Box sx={topbarRightStyling}>
          <TopbarProfile />
        </Box>
      </Box>
    </Toolbar>
  );
}

Topbar.propTypes = {
  // handleToggleSidebar: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Topbar;
