import React from 'react';
import { Paper, Table, TableContainer, } from '@mui/material';
import TableLoader from 'containers/common/components/TableLoader';
import withTable from 'hoc/withTable';
import { useGetAnalyticsQuery } from 'services/private/dashboard';
import TopSellingHotelsTableHead from './TopSellingHotelsTableHead';
import TopSellingHotelsTableBody from './TopSellingHotelsTableBody';

function TopSellingHotelsTable() {
  const { data, isFetching } = useGetAnalyticsQuery();
  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader>
          <TopSellingHotelsTableHead
            rowCount={0}
          />
          {isFetching ? (
            <TableLoader />
          ) : (
            <TopSellingHotelsTableBody
              hotels={data?.top_selling_hotels}
            />
          )}

        </Table>

      </TableContainer>
    </Paper>

  );
}
export default withTable(TopSellingHotelsTable);
