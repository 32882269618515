import image from 'assets/img/bgImage.jpg';

export const userContainerStyles = { '@media screen and (max-width: 990px)': { display: 'none' } };

export const userArrowStyles = {
  display: 'none',
  '@media screen and (max-width: 990px)': { display: 'block' },
};

export const chatTitleStyles = {
  borderBottom: '1px solid #E5E5EA',
  background: '#F9F9F9',
};

export const userListContainerStyles = {
  borderRight: '1px solid #E5E5EA',
  background: '#F9F9F9',
  '@media screen and (max-width: 990px)': { borderRight: 'none' },
};

export const messageListMenuStyles = { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' };

export const messageListMenuItemStyles = {
  '&:hover': { bgcolor: '#ece9eb' },
};

export const MessagesWrapperStyles = {
  overflowY: 'auto',
  height: '60vh',
  background: image,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
};

export const ChatMessageBoxStyles = { boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)' };

export const chatEmojiBoxStyles = { position: 'absolute', top: 0, transform: 'translateY(-100%)' };

export const createOfferModalStyles = {
  width: '600px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  translate: '-50% -50%',
  outline: 'none',
  border: 'none',
  borderRadius: '10px',
  height: '80vh',
  overflow: 'hidden',
  '@media screen and (max-width: 991px)': {
    width: '100vw',
  },
};

export const userProfileImageStyles = { height: '40px', width: '40px' };

export const userInfoContainerStyles = {
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
  marginBottom: '4px',
  borderRadius: '10px',
  '&:hover': {
    background: '#FFE3C5',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)'
  },
};

export const activeUserContainerStyles = {
  background: '#FFE3C5',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  marginBottom: '4px',
};

export const chatDisableButtonStyles = {
  background: '#E0E0E0',
  color: '#B5C6D9 ',
};

export const chatOfferContainerStyles = {
  width: '400px',
};

export const chatOfferBorderStyles = { borderLeft: '3px solid #fff', paddingLeft: '5px' };
