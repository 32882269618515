import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableRow, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useCancelBookingMutation } from 'services/private/booking';
import { useNavigate } from 'react-router-dom';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import NoFoundTable from 'containers/common/components/NoFoundTable';
import CommonMenu from 'containers/common/components/CommonMenu';
import useGetParams from 'customHooks/useGetParams';
import { Options } from './utilities/data';

function BookingTableBody({ booking }) {
  const navigate = useNavigate();
  const [params] = useGetParams();
  const [actionOptions, setActionOptions] = useState(Options);

  const [CancelBooking, { error, isSuccess }] = useCancelBookingMutation();
  useHandleApiResponse(error, isSuccess, 'Booking Cancelled Successfully');

  const handleItemClicked = async (item, data) => {
    if (item.value === 'cancel') {
      await CancelBooking(data.supplier_reference_number);
    } else {
      navigate(`/booking/detail/${data.id}`);
    }
  };

  useEffect(() => {
    setActionOptions(() => [
      ...Options,
      ...(params?.status === 'confirmed' && params?.future === 'true'
        ? [{ value: 'cancel', label: 'Cancel', selectedValue: 'cancel' }]
        : []),
    ]);
  }, [params]);
  if (booking?.length === 0) {
    return <NoFoundTable message="No Booking Found" col={12} />;
  }
  return (
    <TableBody className="p-3">
      {booking?.map(data => (
        <TableRow
          className="material-table__row"
          key={data.id}
          role="button"
          tabIndex="-1"
        >
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.hotel_name || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.created_at && moment(data.created_at).format('D MMM YYYY')}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center', fontWeight: 'bold' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.supplier_reference_number || 'N/A'}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }} component="td" scope="row">
            {data.start_date && moment(data.start_date).format('D MMM YYYY')}
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }} component="td" scope="row">
            {data.end_date && moment(data.end_date).format('D MMM YYYY')}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.requested_currency_code || ''}{' '}
            {data.requested_amount_after_tax || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.total_rooms || 'N/A'}
          </TableCell>
          <TableCell align="center">
            <Chip
              sx={{ textTransform: 'capitalize' }}
              label={data.booking_status}
              color={data.booking_status === 'confirmed' ? 'success' : 'error'}
            />
          </TableCell>

          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            <CommonMenu
              menus={actionOptions}
              func={handleItemClicked}
              data={data}
              isSimple
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
BookingTableBody.propTypes = {
  booking: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BookingTableBody;
