import React, { useState, useLayoutEffect, useEffect, useRef, useMemo } from 'react';
import { Grid, Box, Typography, Paper } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
// STYLES
import {
  MessagesWrapperStyles,
  userListContainerStyles,
} from 'styles/mui/containers/chat-box-styles';
// HOOK
import { useGetRoomQuery, useLazyListAllRoomUsersQuery } from 'services/private/chat';
import useWindowDimensions from 'customHooks/useWindowDimensions';
import useConnectWebSocket from 'customHooks/useConnectWebSocket';
import { getDateSorting, transformOptions } from 'utilities/helpers';
import { ChatContext } from 'context/ChatContext';
import { getAllRoomSocketUrl } from 'utilities/sockets-urls';

// COMPONENTS
import User from './User';
import ChatSearchBar from './ChatSearchBar';
import MessageList from './MessageList';

function ChatBox() {
  const { enqueueSnackbar } = useSnackbar();
  const { user: userInfo } = useSelector(state => state.auth);
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get('room');
  const { data: roomDetail } = useGetRoomQuery({ roomId }, { skip: !roomId });
  const allRoomsSocket = useConnectWebSocket(getAllRoomSocketUrl);

  const [listAllRoomUsers, { isFetching: usersFetching }] = useLazyListAllRoomUsersQuery();
  const { width } = useWindowDimensions();

  // states
  const [chatRoomId, setChatRoomId] = useState(null);
  const [roomsUserList, setRoomsUserList] = useState([]);
  const [showUserList, setShowUserList] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchText, _setSearchText] = useState('');
  const [pendingMessages, setPendingMessages] = useState([]);
  const [disputeUser, setDisputeUser] = useState(null);
  // refs
  const searchTextRef = useRef(searchText);

  const setSearchText = data => {
    searchTextRef.current = data;
    _setSearchText(data);
  };
  const getListAllRoomUsers = () => {
    listAllRoomUsers().then(({ data = [] }) => {
      const sortedUsersList = [...transformOptions(data)].sort(
        getDateSorting('desc', 'last_message_time')
      );
      setRoomsUserList(sortedUsersList);
      if (sortedUsersList?.length > 0) {
        const users = roomDetail ? sortedUsersList.filter(item => item.room_id === roomDetail?.id) : sortedUsersList[0];
        const user = roomDetail ? users[0] : users;
        const isOwner = userInfo?.profile?.id === user?.owner_id;
        const userId = isOwner ? user?.partner_id : user?.owner_id;
        const selectedUserFirstName = isOwner
          ? user?.partner_first_name
          : user?.owner_first_name;
        const selectedUserLastName = isOwner
          ? user?.partner_last_name
          : user?.owner_last_name;
        const selectedUserName = isOwner ? user?.partner_username : user?.owner_username;
        const selectedUserProfile = isOwner ? user?.partner_image : user?.owner_image;
        const selectedUserDispute = isOwner ? user?.dispute : user?.dispute;
        const isSuperAdmin = !isOwner && user?.dispute !== null;
        setChatRoomId(user?.room_id);
        setDisputeUser(isSuperAdmin ? user : null);
        setSelectedUser({
          userId,
          selectedUserFirstName,
          selectedUserLastName,
          selectedUserName,
          selectedUserProfile,
          selectedUserDispute
        });
      }
    });
  };
  useEffect(() => {
    getListAllRoomUsers();
  }, [roomId]);

  useLayoutEffect(() => {
    if (width < 990) setShowUserList(false);
    else setShowUserList(true);
  }, [width]);

  // WEB-SOCKET
  useEffect(() => {
    if (allRoomsSocket) {
      allRoomsSocket.onmessage = e => {
        if (e.data) {
          setTimeout(() => {
            const body = { search: searchTextRef.current };

            listAllRoomUsers(body).then(({ data = [] }) => {
              const sortedUsersList = [...transformOptions(data)].sort(
                getDateSorting('desc', 'last_message_time')
              );
              setRoomsUserList(sortedUsersList);
            });
          }, [100]);
        }
      };
    }
  }, [allRoomsSocket]);

  const handleHitSocketToGetLatestRoom = () => {
    allRoomsSocket.send(JSON.stringify({}));
  };
  const setClearRoomChat = res => {
    if (res) {
      getListAllRoomUsers();
      enqueueSnackbar('Chat is Cleard', { variant: 'success' });
    }
  };
  // CONTEXT VALUE
  const chatContextValue = useMemo(
    () => ({
      chatRoomId,
      pendingMessages,
      setPendingMessages,
      selectedUser,
      handleGetLatestRoom: handleHitSocketToGetLatestRoom,
    }),
    [chatRoomId, pendingMessages, selectedUser, allRoomsSocket]
  );

  return (
    <ChatContext.Provider value={chatContextValue}>
      <Paper>
        <Grid container>
          <Grid item xl={3} lg={5} md={12} sm={12} sx={userListContainerStyles}>
            <Box>
              {showUserList && (
              <Box>
                <Box className="p-3">
                  <ChatSearchBar
                    placeholder="Search Users"
                    searchApi={listAllRoomUsers}
                    loading={usersFetching}
                    setSearchList={setRoomsUserList}
                    setSearchText={setSearchText}
                    searchText={searchText}
                  />
                </Box>

                <Box sx={MessagesWrapperStyles}>
                  <Box sx={{ height: 'auto' }}>
                    {roomsUserList?.map(user => (
                      <User
                        key={user?.room_id}
                        user={user}
                        setChatRoomId={setChatRoomId}
                        chatRoomId={chatRoomId}
                        setSelectedUser={setSelectedUser}
                        setShowUserList={setShowUserList}
                        isCleared={user.is_deleted}
                      />
                    ))}
                  </Box>

                </Box>
              </Box>
              )}

              {!showUserList && (
              <Box className="p-3">
                <Menu
                  sx={{ color: 'primary' }}
                  onClick={() => setShowUserList(!showUserList)}
                />

                <Typography variant="title" color="primary">
                  Chats
                </Typography>
              </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={7} xl={9}>
            <MessageList setClearChat={setClearRoomChat} disputeUser={disputeUser} />
          </Grid>
        </Grid>
      </Paper>
    </ChatContext.Provider>
  );
}

export default ChatBox;
