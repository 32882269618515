import React from 'react';
import { Box } from '@mui/material';
import CommonModal from 'containers/common/components/CommonModal';
import { useTableContext } from 'context/TableContext';
import AssignTicketTable from './AssignTicketTable';

function AssignTicketsModal() {
  const { deleteToggle, deleteModal } = useTableContext();
  return (
    <CommonModal
      isOpen={deleteModal}
      toggle={deleteToggle}
      title="Support Users List"
      isPopup
    >
      <Box sx={{ maxWidth: '800px', minWidth: '800px' }}>
        <AssignTicketTable />
      </Box>

    </CommonModal>
  );
}
export default AssignTicketsModal;
