import { createTheme } from '@mui/material';
import { greyColor, incomeColor } from './themeVariables';

export const palette = {
  primary: {
    main: '#D4B35B',
    contrastText: '#ffffff',
    secondary: '#323232',
  },
  secondary: {
    main: '#323232',
    contrastText: '#FFFFFF',
  },
  greyColor: {
    main: '#7C7C7C',
    contrastText: '#808080',
    gradyAdditional: '#999999',
  },
  chartsPlatte: {
    main: '#022a40',
    secondary: '#a9a9a9',
  },
  tablePallete: {
    headBg: '#f1f8ff',
  },
  mutedColor: {
    main: greyColor,
  },
  success: {
    main: '#8bc34a',
    contrastText: '#FFFFFF',
  },
};

const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },

  typography: {
    fontFamily: "'Roboto', sans-serif !important",
    pageTitle: {
      fontSize: '1.3rem',
      fontWeight: 300,
      fontFamily: 'Roboto, sans-serif',

      '@media (max-width: 991px)': {
        fontSize: '1.2rem',
      },
    },
    successText: {
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: 'Roboto, sans-serif',
      color: incomeColor,

      '@media (max-width: 991px)': {
        fontSize: '11px',
      },
    },
    h4: {
      fontSize: '18px !important',
      lineHeight: '24px',
    },
    h3: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    h5: {
      fontSize: '14px',
    },
    h2: {
      fontSize: '30px',
      lineHeight: '36px',
    },
    warn: {
      fontSize: '12px',
      fontWeight: 300,
      color: '#ff1744',
      fontFamily: 'Roboto, sans-serif',

      '@media (max-width: 991px)': {
        fontSize: '10px',
      },
    },
    smallText: {
      fontSize: '12px',
      fontWeight: 300,
      color: palette.greyColor.contrastText,
      fontFamily: 'Roboto, sans-serif',

      '@media (max-width: 991px)': {
        fontSize: '10px',
      },
    },
    linkText: {
      fontSize: '14px',
      fontWeight: 300,
      color: '#70bbfd',
      fontFamily: 'Roboto, sans-serif',

      '@media (max-width: 991px)': {
        fontSize: '12px',
      },
    },
  },

  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 300,
          color: 'black',
          ':hover': {
            backgroundColor: palette.primary.main,
            color: '#f3f5fa',
          },
          '&:hover .MuiSvgIcon-root': {
            color: '#f3f5fa', // Change the color of the icon on hover
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 300,
          color: 'black',
          ':hover': {
            backgroundColor: palette.primary.main,
            color: '#f3f5fa',
          },
          '&.Mui-selected': {
            // Use '&.Mui-selected' for selected state
            backgroundColor: palette.primary.main,
            color: '#f3f5fa',
            ':hover': {
              backgroundColor: palette.primary.main,
              color: '#f3f5fa',
            },
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          pageTitle: 'h1',
        },
      },
    },

    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },

      styleOverrides: {
        root: {
          paddingLeft: '12px !important',
          paddingRight: '12px !important',
          paddingTop: '12px !important',
        },
      },
    },

    MuiCheckbox: {
      variants: [
        {
          props: { variant: 'mutedColor' },
          style: {
            // '& svg': {
            //   fill: '#dddddd',
            // },
            // '&.Mui-checked': {
            //   '& svg': {
            //     fill: palette.primary.main,
            //   },
            // },
          },
        },
      ],
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          color: '#c90000',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize !important',
        },
      },
      defaultProps: {
        size: 'small',
      },
      variants: [
        {
          props: { variant: 'mutedColor' },
          style: {
            backgroundColor: `${greyColor} !important`,
          },
        },
        {
          props: { variant: 'customVariant' },
          style: {
            backgroundColor: 'white', // Set background color to white
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Increase blur radius and spread radius
            transition: 'box-shadow 0.3s ease', // Add transition for smooth shadow change
            '&:hover': {
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // Increase shadow on hover
            },
          },
        },
      ],
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0,0,0,0.5)',
        },
      },
    },

    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        input: {
          '&::placeholder': {
            fontSize: '12px',
          },
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     containedSizeSmall: {
    //       width: '2px'
    //     },
    //   },
    // },
  },
});

export default theme;
