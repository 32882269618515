/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { Avatar, Box, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useReadNotificationMutation } from 'services/private/notifications';

function Notification({ timeFromNow, handleSetNotifications, handleClose, notification }) {
  const navigate = useNavigate();
  // API HOOKS
  const [readNotification] = useReadNotificationMutation();

  // CONSTANTS
  const isRead = !notification?.notification_is_read;
  const isDescriptionLarge = notification?.description?.length > 20;
  const description = isDescriptionLarge
    ? `${notification?.description.substring(0, 28)}...`
    : notification?.description;
  const isGigTitleLarge = notification?.gig_title?.length > 16;
  const footerText = isGigTitleLarge
    ? `${notification?.gig_title.substring(0, 16)}...`
    : notification?.gig_title;

  const handleNavigate = async notificationResponseData => {
    if (notificationResponseData?.room_id) navigate(`/chat?room=${notificationResponseData?.room_id}`);
  };

  const handleReadNotifications = async () => {
    const res = await readNotification({ id: notification?.id, isRead: true });
    if (res?.data) {
      handleSetNotifications();
      handleNavigate(res?.data);
      handleClose();
    }
  };

  return (
    <MenuItem divider className="py-3" style={isRead ? { backgroundColor: '#f2f2f2', color: 'black' } : {}} onClick={handleReadNotifications}>
      <Box className="w-full">
        <Typography className=" text-end" variant="body3">
          {`${timeFromNow} ${isRead ? '(unread)' : ''}`}
        </Typography>
        <Box className="d-flex align-items-center gap-4">
          <Avatar src={notification?.sender_image} />
          <Box>
            <Box className=" d-flex gap-1 flex-wrap align-items-center">
              <Typography className="font-medium fw-500 ">
                { `${notification?.sender_first_name} ${notification?.sender_last_name}` }
              </Typography>
              {' '}
              <Typography>{notification?.heading}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">
                {description}
              </Typography>

              <Typography variant="body2" className="mt-1 d-flex align-items-center ">
                {footerText}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </MenuItem>
  );
}

Notification.propTypes = {
  timeFromNow: propTypes.string,
  notification: propTypes.object,
  handleSetNotifications: propTypes.func.isRequired,
  handleClose: propTypes.func.isRequired,
};
Notification.defaultProps = {
  timeFromNow: '',
  notification: {},
};
export default Notification;
