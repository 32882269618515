import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, TableBody, TableCell, TableRow } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import { useUpdateUserMutation } from 'services/private/users';
import NoFoundTable from 'containers/common/components/NoFoundTable';
import ConfirmationPopup from 'containers/common/components/ConfirmationPopup';

function UsersTableBody({ usersData }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpenConModal, setIsOpenConModal] = useState(false);
  if (usersData?.length === 0) {
    return <NoFoundTable message="No users Data Found" col={12} />;
  }

  const [updateUser] = useUpdateUserMutation();

  const toggleConModal = () => setIsOpenConModal(!isOpenConModal);

  const handleOnConfirmation = async () => {
    await updateUser({
      id: selectedUser.id,
      body: {
        status: selectedUser.status === 'active' ? 'inactive' : 'active',
      },
    });
    toggleConModal();
  };

  return (
    <TableBody className="p-3">
      {usersData?.map(data => (
        <TableRow
          className="material-table__row"
          key={data.id}
          role="button"
          tabIndex="-1"
        >
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="2px"
          >
            {data.id || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.first_name || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.last_name || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center', textTransform: 'capitalize' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.gender || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.email || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textAlign: 'center' }}
            component="td"
            scope="row"
            padding="none"
          >
            {data.phone_number || 'N/A'}
          </TableCell>

          <TableCell
            sx={{ textAlign: 'center', color: 'primary.main' }}
            component="td"
            scope="row"
            padding="none"
            className="fw-semibold"
          >
            {data.expire_membership ? 'Expire' : 'Active' || 'N/A'}
          </TableCell>
          <TableCell align="center">
            <Chip
              sx={{ textTransform: 'capitalize' }}
              label={data.status}
              icon={<ArrowDropDown />}
              color={data.status === 'active' ? 'success' : 'error'}
              onClick={() => {
                setSelectedUser(data);
                toggleConModal();
              }}
            />
          </TableCell>
        </TableRow>
      ))}
      <ConfirmationPopup
        isOpen={isOpenConModal}
        toggle={toggleConModal}
        onConfirmation={handleOnConfirmation}
        message={`Are you sure you want to ${
          selectedUser?.status === 'active' ? 'Inactive' : 'Active'
        } ${selectedUser?.first_name}?`}
      />
    </TableBody>
  );
}
UsersTableBody.propTypes = {
  usersData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UsersTableBody;
