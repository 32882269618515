export const getAllRoomSocketUrl = () => {
  const token = localStorage.getItem('token');
  return `${process.env.REACT_APP_SOCKET_URL}/chat/update/?token=${token}`;
};

export const webSocketUrl = roomId => {
  const token = localStorage.getItem('token');
  return `${process.env.REACT_APP_SOCKET_URL}/dashboard/chat/?token=${token}&room_id=${roomId}`;
};

export const getNotificationUrl = () => {
  const token = localStorage.getItem('token');
  return `${process.env.REACT_APP_SOCKET_URL}/notification/detail/?token=${token}`;
};
