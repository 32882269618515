import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';
import TableLoader from 'containers/common/components/TableLoader';
import { useGetBookingTypesQuery } from 'services/private/booking';
import usePaginationChange from 'customHooks/usePaginationChange';
import withTable from 'hoc/withTable';
import { ROWS_PER_PAGE } from 'utilities/constants';
import useGetParams from 'customHooks/useGetParams';
import BookingTableHead from './BookingTableHead';
import BookingTableBody from './BookingTableBody';

function BookingTable({ tableProps }) {
  const { handleRequestSort, order, orderBy } = tableProps;
  const { handleChangePagination, page: pageNumber } = usePaginationChange();
  const [apiParams] = useGetParams(10);
  const { data: bookingStatusData, isFetching } = useGetBookingTypesQuery(apiParams);

  return (
    <>
      <Typography textTransform="capitalize" className="my-4" variant="pageTitle">
        {apiParams?.status} Booking List
      </Typography>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <BookingTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={0}
            />
            {isFetching ? (
              <TableLoader />
            ) : (
              <BookingTableBody booking={bookingStatusData?.results} />
            )}
          </Table>

          {bookingStatusData?.results?.length > 0 && (
            <Box
              style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}
              className="mb-3"
            >
              <Pagination
                color="primary"
                shape="rounded"
                count={
                  bookingStatusData?.count
                    ? Math.ceil((bookingStatusData?.count ?? 0) / ROWS_PER_PAGE)
                    : 1
                }
                page={pageNumber}
                onChange={(e, newPage) => handleChangePagination(newPage)}
              />
            </Box>
          )}
        </TableContainer>
      </Paper>
    </>
  );
}
BookingTable.propTypes = {
  tableProps: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default withTable(BookingTable);
