import React, { useState } from 'react';
import { Box, Button, CircularProgress, Pagination, Table, TableContainer } from '@mui/material';
import TableLoader from 'containers/common/components/TableLoader';
import styles from 'styles/common/card-pagination.module.scss';
import PropTypes from 'prop-types';
import withTable from 'hoc/withTable';
import { useGetSupportUsersQuery } from 'services/private/adminusers';
import { useTableContext } from 'context/TableContext';
import { useAssignReportsMutation } from 'services/private/helpandsupport';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import { useSelector } from 'react-redux';
import AssignTicketTableBody from './AssignTicketTableBody';
import AssignTicketTableHead from './AssignTicketTableHead';

function AssignTicketTable({ tableProps }) {
  const { handleRequestSort, order, orderBy } = tableProps;
  const { deleteToggle, setSelected, selected } = useTableContext();
  const { user } = useSelector(state => state.auth);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const offset = (page - 1) * itemsPerPage;
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [assignReports, { error, isSuccess, isLoading }] = useAssignReportsMutation();
  useHandleApiResponse(error, isSuccess, 'Ticket Assigned Successfully to Your Self');
  const { data: supportUsersData, isFetching } = useGetSupportUsersQuery({ offset, page, limit: itemsPerPage });
  const handelClick = async id => {
    const payload = {
      report_numbers: selected,
      assignee: id
    };
    await assignReports(payload);
    deleteToggle();
    setSelected([]);
  };
  return (
    <>
      <Box className="d-flex justify-content-end p-2">
        <Button
          variant="contained"
          startIcon={isLoading ? <CircularProgress size={20} /> : undefined}
          disabled={isLoading}
          onClick={() => handelClick(user?.profile?.id)}
        >
          Assign to me
        </Button>
      </Box>
      <TableContainer className="p-2">
        <Table stickyHeader>
          <AssignTicketTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={0}
          />
          {isFetching ? (
            <TableLoader />
          ) : (
            <AssignTicketTableBody userDetail={supportUsersData?.results} />
          )}
        </Table>

        {supportUsersData?.results?.length > 0 && (
        <Box className={styles.pagination} marginTop={2}>
          <Pagination
            count={Math.ceil(supportUsersData.count / itemsPerPage)}
            page={page}
            onChange={handleChange}
            color="primary"
            shape="rounded"
          />
        </Box>
        )}
      </TableContainer>
    </>
  );
}
AssignTicketTable.propTypes = {
  tableProps: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default withTable(AssignTicketTable);
