import React from 'react';
import { Typography, Box } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import propTypes from 'prop-types';

import { formatDate } from 'utilities/helpers';

function BookingGuestDetails({
  startDate,
  endDate,
  totalRooms,
  totalChilds,
  totalAdults,
  totalStayDays,
}) {
  return (
    <Box>
      <Typography variant="pageTitle" className="mb-4">
        Your Booking Details
      </Typography>
      <Box className="d-flex align-items-center gap-5 py-1">
        <Box>
          <Typography variant="body2" sx={{ color: '#8D8D8D' }}>
            Check in
          </Typography>
          <Typography fontWeight={500}>{formatDate(startDate)}</Typography>
        </Box>
        <ArrowRightAlt fontSize="large" />
        <Box>
          <Typography variant="body2" sx={{ color: '#8D8D8D' }}>
            Check out
          </Typography>
          <Typography fontWeight={500}>{formatDate(endDate)}</Typography>
        </Box>
      </Box>
      <Box className="mt-2 py-1">
        <Typography variant="body2" sx={{ color: '#8D8D8D' }}>
          Total Stay
        </Typography>
        <Typography fontWeight={500}>{totalStayDays} Nights</Typography>
      </Box>
      <Box className="mt-2 py-1">
        <Typography variant="body2" sx={{ color: '#8D8D8D' }}>
          Guest Details
        </Typography>
        <Typography fontWeight={500}>
          {totalRooms} Room, {totalAdults} Adult, {totalChilds} Child
        </Typography>
      </Box>
    </Box>
  );
}

BookingGuestDetails.propTypes = {
  startDate: propTypes.string.isRequired,
  endDate: propTypes.string.isRequired,
  totalChilds: propTypes.string.isRequired,
  totalRooms: propTypes.string.isRequired,
  totalAdults: propTypes.string.isRequired,
  totalStayDays: propTypes.string.isRequired,
};
export default BookingGuestDetails;
