import React from 'react';
import propTypes from 'prop-types';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { setIconByFileType } from 'utilities/helpers';
import { saveAs } from 'file-saver';

function GridLabelValuePair({ label, value, mb }) {
  const handleSaveFile = file => {
    saveAs(file);
  };
  return (
    <Box>
      {label !== 'Attachments' && (
        <Box>
          <Grid container mb={mb}>
            <Grid item xs={12} md={4}>
              <Typography variant="h4" fontWeight={600}>
                {label}:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography variant="body1">{value}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {label === 'Attachments' && (
        <Grid container mb={mb}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" fontWeight={600}>
              {label}:
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            {/* FILES CONTAINER */}
            <Box className="d-flex align-items-center my-3 gap-4">
              {value?.map(fileObj => (
                <Card
                  sx={{ cursor: 'pointer', borderRadius: '10px' }}
                  onClick={() => handleSaveFile(fileObj.file)}
                  key={fileObj?.id}
                >
                  <CardContent className="text-center py-3 px-2">
                    <img src={setIconByFileType(fileObj?.file)} alt="file-Icon" />
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
GridLabelValuePair.propTypes = {
  label: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  mb: propTypes.number,
};

GridLabelValuePair.defaultProps = {
  mb: 2,
};
export default GridLabelValuePair;
