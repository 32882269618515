import { privateAPI } from './index';

export const dashboardAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getAnalytics: build.query({
      query: () => ({
        url: '/admin-dashboard/dashboard-analytics/',
        method: 'GET',
      }),
    }),
  }),
});
export const {
  useGetAnalyticsQuery,
} = dashboardAPI;
