import { privateAPI } from './index';

export const usersAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getAllUsers: build.query({
      query: params => ({
        url: '/admin-dashboard/all-users/',
        method: 'GET',
        params,
      }),
      providesTags: ['getAllUsers']
    }),
    updateUser: build.mutation({
      query: body => ({
        url: `/admin-dashboard/user/${body.id}/`,
        method: 'PATCH',
        body: body.body,
      }),
      invalidatesTags: ['getAllUsers', 'getAllAdminUsers']
    }),
  }),
});
export const { useGetAllUsersQuery, useUpdateUserMutation } = usersAPI;
