/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import useAuthInfo from 'customHooks/useAuthInfo';

import { Book, Person } from '@mui/icons-material';
import {
  DashboardItem,
  HelpAndSupportItem,
  BookingSubTabs,
  UsersSubTabs,
  privateJetItem,
  limousineItem,
  restaurantItem,
} from '../../utilities/data';

const useGetSidebarLinks = () => {
  const {
    isSuperUser,
    isOperationsHead,
    isOperationsStaff,
    isHelpAndSupportHead,
    isHelpAndSupportStaff,
  } = useAuthInfo();
  const superUserSidebar = [
    {
      title: 'Main',
      type: 'lable',
    },
    DashboardItem,
    ...(isSuperUser
      ? [
          {
            title: 'Users',
            type: 'lable',
          },
          {
            data: UsersSubTabs,
            title: 'Users',
            path: null,
            icon: <Person />,
            permissions: [],
          },
        ]
      : []),
    ...(isSuperUser || isHelpAndSupportHead || isHelpAndSupportStaff
      ? [
          {
            title: 'Help & Support',
            type: 'lable',
          },
          HelpAndSupportItem,
        ]
      : []),
    ...(isSuperUser || isOperationsHead || isOperationsStaff
      ? [
          {
            title: 'Booking',
            type: 'lable',
          },
          {
            data: BookingSubTabs,
            title: 'Hotels',
            path: null,
            icon: <Book />,
            permissions: [],
          },

            privateJetItem,
            limousineItem,
            restaurantItem,

        ]
      : []),
  ];

  return {
    superUserSidebar,
  };
};

export default useGetSidebarLinks;
