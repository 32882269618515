import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { bookingTableHeadings } from './utilities/data';

function BookingTableHead({ order, orderBy }) {
  // const createSortHandler = property => event => {
  //   onRequestSort(event, property);
  // };

  const { palette } = useTheme();
  const primary = useMemo(() => palette.tablePallete.headBg, []);
  return (
    <TableHead
      sx={{ ' & th': { backgroundColor: primary, padding: '10px 0', textAlign: 'center' } }}
    >
      <TableRow>
        {bookingTableHeadings.map((row, index) => (
          <TableCell
            key={index}
            padding="none"
            sortDirection={orderBy === row.id ? order : false}
            className="fw-semibold"
          >
            <div className={row.disablePadding ? '' : 'p-2'}>{row.label}</div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
BookingTableHead.propTypes = {
  // onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};
export default BookingTableHead;
