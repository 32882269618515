import { Analytics, Group, LocalAtm } from '@mui/icons-material';

export const analyticsCardsList = {
  label1: 'Amount With Tax',
  icon1: LocalAtm,
  label2: 'Bookings',
  icon2: Analytics,
  label3: 'Users',
  icon3: Group,
  label4: 'Amount Without Tax',
  icon4: LocalAtm,

};

export const topCityTableHeadings = [
  {
    id: 'city-name',
    disablePadding: true,
    label: 'City',
  },
  {
    id: 'total-booking',
    disablePadding: true,
    label: 'Bookings',
  },
];
export const topSellingHotelsTableHeadings = [
  {
    id: 'hotel_booking__hotel_name',
    disablePadding: true,
    label: 'Hotel Name',
  },
  {
    id: 'total_requested_amount_before_tax',
    disablePadding: true,
    label: 'Amount Without Tax',
  },
  {
    id: 'total_requested_amount_after_tax',
    disablePadding: true,
    label: 'Amount With Tax',
  },
];
