import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

function BackBtn() {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <Button size="small" variant="contained" color="primary" onClick={handleNavigate}>
      Back
    </Button>
  );
}

export default BackBtn;
