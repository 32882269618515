import React, { useMemo } from 'react';
import { TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { topCityTableHeadings } from '../utilities/data';

function TopCitiesTableHead() {
  const { palette } = useTheme();
  const primary = useMemo(() => palette.tablePallete.headBg, []);
  return (
    <TableHead
      sx={{ ' & th': { backgroundColor: primary, padding: '10px', textAlign: 'center' } }}
    >
      <TableRow>
        {topCityTableHeadings.map((row, index) => (
          <TableCell
            key={index}
            padding="none"
            className="fw-semibold"
          >
            <div className={row.disablePadding ? '' : 'p-2'}>{row.label}</div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TopCitiesTableHead;
