import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { UsersTableHeadings } from '../utilities/data';

function UsersTableHead({ order, orderBy }) {
  const { palette } = useTheme();
  const primary = useMemo(() => palette.tablePallete.headBg, []);
  return (
    <TableHead
      sx={{ ' & th': { backgroundColor: primary, padding: '10px', textAlign: 'center' } }}
    >
      <TableRow>
        {UsersTableHeadings.map((row, index) => (
          <TableCell
            key={index}
            padding="none"
            sortDirection={orderBy === row.id ? order : false}
            className="fw-semibold"
          >
            <div className={row.disablePadding ? '' : 'p-2'}>{row.label}</div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
UsersTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default UsersTableHead;
