/* eslint-disable react/react-in-jsx-scope, react/jsx-filename-extension */
import {
  Dashboard,
  SupportAgent,
  FlightTakeoffTwoTone,
  DirectionsCar,
  RestaurantMenu
} from '@mui/icons-material';

export const DashboardItem = {
  title: 'Dashboard',
  path: '/',
  icon: <Dashboard />,

};
export const HelpAndSupportItem = {
  title: 'Customer Service',
  path: '/help-and-support',
  icon: <SupportAgent />,

};
export const privateJetItem = {
  title: 'Private Jets',
  path: '/private-jets',
  icon: <FlightTakeoffTwoTone />,

};
export const limousineItem = {
  title: 'Limousine',
  path: '/limousine',
  icon: <DirectionsCar />,

};
export const restaurantItem = {
  title: 'Restaurant',
  path: '/restaurant',
  icon: <RestaurantMenu />,

};
export const BookingSubTabs = [
  {
    title: 'Upcoming ',
    path: '/bookings?status=confirmed&future=true&past=false',
  },
  {
    title: 'Previous ',
    path: '/bookings?status=confirmed&future=false&past=true',
  },
  {
    title: 'Cancelled',
    path: '/bookings?status=cancelled&future=false&past=false',
  },

];

export const UsersSubTabs = [
  {
    title: 'Sublime Users ',
    path: '/users/sublime-users',
  },
  {
    title: 'Admin Users',
    path: '/users/admin-users',
  },
];
