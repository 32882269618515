import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ROWS_PER_PAGE } from 'utilities/constants';
import { getSearchParamsList } from 'utilities/helpers';

function useGetParams(limit) {
  const [paramsState, setParamsState] = useState(null);
  const [searchParams] = useSearchParams();
  const paramsList = getSearchParamsList(searchParams);
  const {
    rows_per_page: rowsPerPage = limit || ROWS_PER_PAGE,
    page = 0,
  } = paramsList;
  useEffect(() => {
    const offset = Number(page - 1) * Number(rowsPerPage);
    const params = {
      limit: rowsPerPage,
      offset,
      page,
      ...paramsList,
    };
    setParamsState(params);
  }, [searchParams]);

  return [paramsState, setParamsState];
}

export default useGetParams;
