/* eslint-disable no-unused-vars */
import { Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { KeyboardArrowDown } from '@mui/icons-material';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetThemeColor from 'customHooks/useGetThemeColor';

function CommonMenu({
  disabledValue,
  disabled,
  menus,
  selectedOption,
  isSimple,
  func,
  variant,
  isOverdue,
  overDueType,
  isPageTitle,
  data,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const primaryColor = useGetThemeColor();

  const navigate = useNavigate();

  const handleMenuClick = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(index => {
    setAnchorEl(null);
    setSelectedIndex(index);
  }, []);

  const commonMenuStyling = useMemo(
    () => ({
      '&.Mui-selected, &:hover': {
        backgroundColor: `${primaryColor} !important`,
      },
      '&.Mui-selected span': {
        color: 'white !important',
      },
    }),
    [primaryColor]
  );

  return (
    <>
      <Button
        disabled={disabled}
        onClick={handleMenuClick}
        variant={isSimple ? variant : 'pageTitle'}
        size={isSimple ? 'small' : ''}
        sx={{
          color: isSimple ? '' : 'black',
          fontWeight: isOverdue ? 'bold' : '',
          marginRight: isOverdue ? '-2.5rem' : '',
          fontSize: isPageTitle ? '1.3rem' : null,
        }}
      >
        {selectedOption} <KeyboardArrowDown />
      </Button>
      <Menu anchorEl={anchorEl} open={anchorEl} onClose={handleClose}>
        {menus.map((menu, idx) => (
          <MenuItem
            key={`${idx}-${menu.label}`}
            sx={{
              ...commonMenuStyling,
              minWidth: isPageTitle ? '120px' : null,
              paddingLeft: isPageTitle ? '25px' : null,
              // backgroundColor: (idx === selectedIndex) ? primaryBg : null,
            }}
            selected={idx === selectedIndex}
            onClick={() => {
              func(menu, data);
              handleClose(idx);
            }}
          >
            {menu.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

CommonMenu.propTypes = {
  menus: PropTypes.array.isRequired,
  selectedOption: PropTypes.string,
  isSimple: PropTypes.bool,
  func: PropTypes.func,
  variant: PropTypes.string,
  isOverdue: PropTypes.bool,
  overDueType: PropTypes.string,
  isPageTitle: PropTypes.bool,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  disabledValue: PropTypes.bool,
};

CommonMenu.defaultProps = {
  isSimple: false,
  func: () => {},
  variant: 'contained',
  isOverdue: false,
  overDueType: 'receivable',
  isPageTitle: false,
  selectedOption: 'Actions',
  data: null,
  disabled: false,
  disabledValue: undefined,
};

export default CommonMenu;
