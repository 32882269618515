import { privateAPI } from './index';

export const adminUsersAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getAllAdminUsers: build.query({
      query: params => ({
        url: '/admin-dashboard/all-admin-user/',
        method: 'GET',
        params,
      }),
      providesTags: ['getAllAdminUsers']
    }),
    getSingleAdminUsers: build.query({
      query: body => ({
        url: `/admin-dashboard/user/${body.userId}/`,
        method: 'GET',
      }),
    }),
    getSupportUsers: build.query({
      query: params => ({
        url: '/admin-dashboard/all-admin-user/?user_type=staff&department=help_and_support',
        method: 'GET',
        params,
      }),
      providesTags: ['getSupportUsers']
    }),
    getAllDepartment: build.query({
      query: () => ({
        url: '/admin-dashboard/department-list/',
        method: 'GET',
      }),
    }),
    createAdminUsers: build.mutation({
      query: body => ({
        url: '/admin-dashboard/registration-admin/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getAllAdminUsers'],
    }),
    updateAdminUsers: build.mutation({
      query: body => ({
        url: '/admin-dashboard/user/',
        method: 'PUT',
        body,
      }),
    }),
  }),
});
export const {
  useGetAllAdminUsersQuery,
  useGetAllDepartmentQuery,
  useCreateAdminUsersMutation,
  useGetSupportUsersQuery,
  useGetSingleAdminUsersQuery,
  useUpdateAdminUsersMutation,
} = adminUsersAPI;
