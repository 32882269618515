import * as yup from 'yup';

export const superUserInitialValues = {
  first_name: '',
  last_name: '',
  role: '',
  email: '',
  department: '',
};
export const superUserValidationSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  role: yup.string().required('Role is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  department: yup.string().required('Department is required'),
});
