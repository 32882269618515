import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import AdminUserTable from './components/AdminUserTable';

function AdminUsers() {
  return (
    <>
      <Typography className="my-4" variant="pageTitle">
        Admin Users
      </Typography>
      <Box className="d-flex justify-content-end mb-4">
        <Link to="/users/add-admin-user" className="resetLink">
          <Button variant="contained" startIcon={<PersonAdd />}>
            Add User
          </Button>
        </Link>
      </Box>

      <AdminUserTable />
    </>
  );
}

export default AdminUsers;
