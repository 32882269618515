import { toTitleCase } from 'utilities/helpers';

export const getTransformedDepartmentOptions = (department = []) => {
  const allDepartments = department?.map(item => ({
    value: item?.id,
    label: toTitleCase(item?.department_name),
  }));

  return allDepartments || [];
};
export const editUserInfoData = data => ({
  first_name: data?.first_name || '',
  last_name: data?.last_name || '',
  role: data?.user_type || '',
  email: data?.email || '',
  department: data?.department || '',

});
