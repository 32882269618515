// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat_createWorkspace__q0CNS .chat_jobPosting__FU19m {
  background: #ffffff;
  border-radius: 10px;
}
.chat_createWorkspace__q0CNS .chat_skillsContainer__oby3E {
  border: 1px solid #ece9eb;
  border-radius: 5px;
}
.chat_createWorkspace__q0CNS .chat_dotsIcon__nC0hJ {
  width: 54px;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
}
.chat_createWorkspace__q0CNS .chat_dotsIcon__nC0hJ:hover {
  background: #fff6f2;
  width: 54px;
  justify-content: center;
  border-radius: 5px;
}
.chat_createWorkspace__q0CNS .chat_textAreaInput__OH1pV {
  font-size: 16px !important;
  color: #000000 !important;
}
.chat_createWorkspace__q0CNS ::placeholder {
  color: #000000 !important;
}
.chat_createWorkspace__q0CNS .chat_divider__GQD07 {
  color: #ece9eb;
}
.chat_createWorkspace__q0CNS .chat_uploadBtn__2D\\+OM {
  background: #fbf9f1;
  border: 1px dashed #ebe4e7;
}
.chat_createWorkspace__q0CNS .chat_uploadBtn__2D\\+OM:hover {
  background: #ececec;
}
.chat_createWorkspace__q0CNS .chat_uploadedFile__eJQtt {
  border: 1px solid #ebedf4;
  border-radius: 15px;
}
.chat_createWorkspace__q0CNS .chat_cancelButton__Yuiyd {
  background: #fce8e8;
  border-radius: 5px;
  color: #ee2222;
  padding: 2px;
}
.chat_createWorkspace__q0CNS .chat_cancelBtnText__ubpa3 {
  font-size: 1rem !important;
}
.chat_createWorkspace__q0CNS .chat_skillsEdit__JDKAV {
  width: 200px !important;
}
@media only screen and (max-width: 480px) {
  .chat_createWorkspace__q0CNS .chat_textAreaInput__OH1pV {
    font-size: 12px !important;
    color: #000000 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/common/chat.module.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,kBAAA;AADJ;AAIE;EACE,WAAA;EACA,uBAAA;EACA,yBAAA;UAAA,iBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,WAAA;EACA,uBAAA;EACA,kBAAA;AAHJ;AAME;EACE,0BAAA;EACA,yBAAA;AAJJ;AAOE;EACE,yBAAA;AALJ;AAQE;EACE,cAAA;AANJ;AASE;EACE,mBAAA;EACA,0BAAA;AAPJ;AAUE;EACE,mBAAA;AARJ;AAWE;EACE,yBAAA;EACA,mBAAA;AATJ;AAYE;EACE,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AAVJ;AAaE;EACE,0BAAA;AAXJ;AAcE;EACE,uBAAA;AAZJ;AAeE;EACE;IACE,0BAAA;IACA,yBAAA;EAbJ;AACF","sourcesContent":[".createWorkspace {\n  & .jobPosting {\n    background: #ffffff;\n    border-radius: 10px;\n  }\n\n  & .skillsContainer {\n    border: 1px solid #ece9eb;\n    border-radius: 5px;\n  }\n\n  & .dotsIcon {\n    width: 54px;\n    justify-content: center;\n    user-select: none;\n  }\n\n  & .dotsIcon:hover {\n    background: #fff6f2;\n    width: 54px;\n    justify-content: center;\n    border-radius: 5px;\n  }\n\n  & .textAreaInput {\n    font-size: 16px !important;\n    color: #000000 !important;\n  }\n\n  & ::placeholder {\n    color: #000000 !important;\n  }\n\n  & .divider {\n    color: #ece9eb;\n  }\n\n  & .uploadBtn {\n    background: #fbf9f1;\n    border: 1px dashed #ebe4e7;\n  }\n\n  & .uploadBtn:hover {\n    background: #ececec;\n  }\n\n  & .uploadedFile {\n    border: 1px solid #ebedf4;\n    border-radius: 15px;\n  }\n\n  & .cancelButton {\n    background: #fce8e8;\n    border-radius: 5px;\n    color: #ee2222;\n    padding: 2px;\n  }\n\n  & .cancelBtnText {\n    font-size: 1rem !important;\n  }\n\n  & .skillsEdit {\n    width: 200px !important;\n  }\n\n  @media only screen and (max-width: 480px) {\n    & .textAreaInput {\n      font-size: 12px !important;\n      color: #000000 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createWorkspace": `chat_createWorkspace__q0CNS`,
	"jobPosting": `chat_jobPosting__FU19m`,
	"skillsContainer": `chat_skillsContainer__oby3E`,
	"dotsIcon": `chat_dotsIcon__nC0hJ`,
	"textAreaInput": `chat_textAreaInput__OH1pV`,
	"divider": `chat_divider__GQD07`,
	"uploadBtn": `chat_uploadBtn__2D+OM`,
	"uploadedFile": `chat_uploadedFile__eJQtt`,
	"cancelButton": `chat_cancelButton__Yuiyd`,
	"cancelBtnText": `chat_cancelBtnText__ubpa3`,
	"skillsEdit": `chat_skillsEdit__JDKAV`
};
export default ___CSS_LOADER_EXPORT___;
