import React, { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { AttachFile, Close, Send, SentimentSatisfiedAlt, } from '@mui/icons-material';
import { chatEmojiBoxStyles } from 'styles/mui/containers/chat-box-styles';
import EmojiPicker, { Theme as EmojiTheme, EmojiStyle } from 'emoji-picker-react';
import propTypes from 'prop-types';
import { v4 } from 'uuid';

// API HOOKS
import { useSendChatAttachmentsMutation } from 'services/private/chat';

// COMPONENTS & CUSTOM HOOKS & STYLES & UTILITIES
import { FormikField } from 'shared/form';
import { setIconByFileType } from 'utilities/helpers';
import styles from 'styles/common/chat.module.scss';

import useGetChatContext from 'customHooks/useGetChatContext';
import { sendMessageInitialValue, sendMessageValidation } from '../utilities/forrmUtilis';

function ChatMessageForm({ socket }) {
  const uploadFileRef = useRef(null);

  const { chatRoomId, setPendingMessages } = useGetChatContext();
  const [isAttachFile, setIsAttachFile] = useState(false);
  const [isEmojiBoxOpen, setIsEmojiBoxOpen] = useState(false);

  // API HOOKS
  const [sendChatAttachments, { isLoading }] = useSendChatAttachmentsMutation();

  // HANDLERS
  const handleUploadFileChange = async (e, setFieldValue) => {
    const imageFile = e.target.files[0];
    imageFile.imgSrc = URL.createObjectURL(imageFile);
    const uploadAttachResp = await sendChatAttachments({ file: imageFile });
    const imageFileObject = {
      file: imageFile,
      id: uploadAttachResp?.data?.id,
    };
    setIsAttachFile(true);
    setFieldValue('attachment', imageFileObject);
  };
  const handleAddEmoji = (emojiData, _, values, setFieldValue) => {
    setFieldValue('message', values.message + emojiData.emoji);
  };
  const handleToggleEmojiBox = () => {
    setIsEmojiBoxOpen(!isEmojiBoxOpen);
  };
  const handleCloseEmojiBox = () => {
    setIsEmojiBoxOpen(false);
  };

  return (
    <Formik
      initialValues={sendMessageInitialValue}
      validationSchema={!isAttachFile && sendMessageValidation}
      onSubmit={(submittedValues, { resetForm }) => {
        if (submittedValues?.attachment) {
          const payload = {
            message: submittedValues?.message,
            file: submittedValues?.attachment?.id,
            is_file: true,
            room: chatRoomId,
          };
          const sendMessagePayload = JSON.stringify(payload);
          socket.send(sendMessagePayload);
        } else {
          const payload = {
            message: submittedValues?.message,
            room: chatRoomId,
          };

          const sendMessagePayload = JSON.stringify(payload);

          setPendingMessages(prevState => [...prevState, { id: v4(), ...payload }]);
          socket.send(sendMessagePayload);
        }
        resetForm();
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <Box className="d-flex align-items-center" sx={{ background: '#F9F9F9' }}>
            <Box className=" position-relative">
              <IconButton onClick={handleToggleEmojiBox}>
                {isEmojiBoxOpen ? <Close /> : <SentimentSatisfiedAlt />}
              </IconButton>
              {isEmojiBoxOpen && (
                <Box sx={chatEmojiBoxStyles}>
                  <EmojiPicker
                    skinTonesDisabled
                    onEmojiClick={(data, e) => handleAddEmoji(data, e, values, setFieldValue)}
                    theme={EmojiTheme.AUTO}
                    emojiStyle={EmojiStyle.NATIVE}
                  />
                </Box>
              )}
            </Box>

            <Box className="col mb-2">
              <FormikField
                placeholder="Type your message here"
                name="message"
                fullWidth
              />
            </Box>

            <Stack direction="row">
              {isLoading ? (
                <IconButton>
                  <CircularProgress size={20} />
                </IconButton>
              ) : (
                <IconButton onClick={() => uploadFileRef.current?.click()}>
                  <AttachFile />
                </IconButton>
              )}

              <input
                type="file"
                ref={uploadFileRef}
                hidden
                onChange={e => handleUploadFileChange(e, setFieldValue)}
              />

              <IconButton onClick={() => { handleSubmit(); handleCloseEmojiBox(); }}>
                <Send />
              </IconButton>
            </Stack>
          </Box>

          {values?.attachment && (
            <Box className="bg-white p-2 d-flex">
              <Box key="waqas" className={`${styles.uploadedFile} p-2 me-2 mt-2`} sx={{ background: '#dee2e6', borderRadius: '15px ' }}>
                <Box className="d-flex justify-content-between">
                  <Box className="d-flex">
                    <img src={setIconByFileType(values?.attachment?.file?.type)} alt="file" />

                    <Box className="d-flex align-items-center">
                      <Typography className="weight-400 ms-2" color="black">
                        {values?.attachment?.file?.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="d-flex align-items-center ms-3">
                    <IconButton
                      aria-label="delete"
                      className={styles.cancelButton}
                      onClick={() => setFieldValue('attachment', '')}
                    >
                      <Close className={styles.cancelBtnText} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
}

ChatMessageForm.propTypes = {
  socket: propTypes.object,
};

ChatMessageForm.defaultProps = {
  socket: null,
};

export default ChatMessageForm;
