import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSearchParamsList } from 'utilities/helpers';
import useGetSearchParams from './useGetSearchParams';

const usePaginationChange = () => {
  const { page: pageNumber, searchParams } = useGetSearchParams();
  const [searchParamsState, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (pageNumber) {
      setPage(Number(pageNumber));
    }
  }, [pageNumber]);

  const handleChangePage = newPage => {
    const paramsList = getSearchParamsList(searchParams);
    setSearchParams({ ...paramsList, page: newPage });
  };

  const handleChangePagination = useCallback(
    newPage => {
      setPage(newPage);
      handleChangePage(newPage);
    },
    [searchParamsState]
  );

  return { handleChangePagination, page, setPage };
};

export default usePaginationChange;
