import React, { useMemo } from 'react';
import { Checkbox, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTableContext } from 'context/TableContext';
import { helpAndSupportTableHeadings } from '../utilities/data';

function HelpAndSupportTableHead() {
  const { data, numSelected, handleSelectAllClick, order, orderBy } = useTableContext();
  const { palette } = useTheme();
  const primary = useMemo(() => palette.tablePallete.headBg, []);
  const { user: userInfo } = useSelector(state => state.auth);

  return (
    <TableHead sx={{ ' & th': { backgroundColor: primary, padding: '10px', textAlign: 'center' }, }}>
      <TableRow>
        {userInfo?.user_type === 'admin' && (
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < data.length}
            checked={numSelected === data.length}
            onChange={e => handleSelectAllClick(e, data, 'report_number')}
          />
        </TableCell>
        )}

        {helpAndSupportTableHeadings.map(
          row => (
            <TableCell
              key={row.id}
              align="left"
              className="fw-semibold"
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
              style={{ minWidth: '150px' }}
            >
              {row.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default HelpAndSupportTableHead;
